
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreMixin } from '@mixin/store';
import ChainSvg from '@svg/Chain.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: { ChainSvg },
    mixins: [StoreMixin],
    methods: {
        copyUrl() {
            var el = document.createElement('textarea');
            el.value = window.location.href;
            el.setAttribute('readonly', '');
            el.style = { position: 'absolute', left: '-9999px' };
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.dispatch('setToastMessage', 'Link gekopieerd.');
            this.dispatch('setToastButton', '');
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CopyUrl extends Vue {}
