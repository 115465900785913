

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import CopyUrl from '@part/elements/CopyUrl.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: {
        data: {
            type: Object,
            required: true
        } 
    },
    components: {
        CopyUrl
    },
    computed: {
    },
    methods: {
        
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Author extends Vue {};

