
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { setTitle, handle429 } from '@util/util.ts';
import Author from '@part/elements/Author.vue';
import TextBlock from '@part/blocks/TextBlock.vue';
import AccordionBlock from '@part/blocks/AccordionBlock.vue';
import EventBlock from '@part/blocks/EventBlock.vue';
import QuoteBlock from '@part/blocks/QuoteBlock.vue';
import ImageBlock from '@part/blocks/ImageBlock.vue';
import ImageTextBlock from '@part/blocks/ImageTextBlock.vue';
import CallToActionBlock from '@part/blocks/CallToActionBlock.vue';
import ColumnsBlock from '@part/blocks/ColumnsBlock.vue';
import TableBlock from '@part/blocks/TableBlock.vue';
import MuseumBlock from '@part/blocks/MuseumBlock.vue';
import VideoBlock from '@part/blocks/VideoBlock.vue';

import axios from 'axios';
import EventOverview from './EventOverview.vue';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        Author,
        AccordionBlock,
        CallToActionBlock,
        ColumnsBlock,
        EventBlock,
        ImageBlock,
        ImageTextBlock,
        MuseumBlock,
        QuoteBlock,
        TableBlock,
        TextBlock,
        VideoBlock
    },
    data() {
        return {
            pageData: null,
            AccordionBlock: AccordionBlock,
            CallToActionBlock: CallToActionBlock,
            EventBlock: EventBlock,
            ImageTextBlock: ImageTextBlock,
            ImageBlock: ImageBlock,
            MuseumBlock: MuseumBlock,
            QuoteBlock: QuoteBlock,
            TableBlock: TableBlock,
            TextBlock: TextBlock,
            VideoBlock: VideoBlock
        };
    },
    mounted() {
        let $this = this;
        axios
            .get(`${app.api.api}/page/${$this.$route.meta.id}`)
            .then(response => {
                $this.pageData = response.data;
                setTitle(response);
            })
            .catch(error => {
                console.log(`🔥${error}🔥`);
                handle429(this, error);
            });
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Longread extends Vue {}
